import React from "react";
import { connect } from "react-redux";
import { menuRightClose, menuUrlOpen } from "../Redux/actions";

function MainMenuButton(props) {
  const audioFile = new Audio(props.audioProp);
  audioFile.addEventListener("ended", () => {

    //window.location.href = props.urlProp;
  });
  function toggleAudio() {
    //audioFile.play();
    props.menuRightClose();
    //props.menuUrlOpen();
    //console.log(props)
    window.open(props.urlProp, "_blank");
/*
    props.dispatch({
      type: 'MENU_URL_OPEN',
      payload: props.urlProp
    });
    */

  }
  return (
      <a className="info-link" onClick={toggleAudio}>
        {props.textProp}
      </a>
  );
}


const mapDispatchToProps = dispatch =>  ({
    menuRightClose,
    menuUrlOpen,
    dispatch
});
export default connect(null, mapDispatchToProps)(MainMenuButton);
